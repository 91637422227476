const modal = document.querySelector('#modal')
const backdrop = document.querySelector('.backdrop')
const showModalBtn = document.querySelectorAll('.showModalBtn')
const closeBtn = document.querySelector('.closeModalBtn')

const closeModal = () => {
  backdrop.style.display = 'none'
  modal.style.display = 'none'
  modal.style.top = '-180%'
}

const scrollTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0;
}

const showModal = () => {
  backdrop.style.display = 'block'
  modal.style.display = 'block'
  scrollTop()
  modal.style.top = 0
}


// Event listeners
for (let i = 0; i < showModalBtn.length; i++) {
  showModalBtn[i].addEventListener('click', showModal)
}
backdrop.addEventListener('click', closeModal)
closeBtn.addEventListener('click', closeModal)
document.addEventListener('keydown', e => {
  if (e.key === 'Escape') closeModal()
})